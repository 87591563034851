import React from 'react';
import logo from './logo.png';
import './App.css';
import './Home.css';
import Particles from 'react-particles-js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faNewspaper } from '@fortawesome/free-solid-svg-icons'
import particlesConfig from './particlesjs-config.json'

function Home() {
  return (
    <div className="App">
      <header className="App-header grid">
        <div className="App-logo g_logo logo_big" alt="logo"><span className="g_logo_body"><span className="g_logo-red">GERBODYNE</span></span></div>
        
        <p className="App-subheader">
            A Video Game Studio
        </p>
      </header>
     
    </div>
  );
}

export default Home;

