import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
import Home from './Home';
const About = () => <div>About</div>;
const Dashboard = () => <div>Dashboard</div>;

const Routes = () => (
    <div>
        <Switch>
            <Route exact path="/">
            <Home />
            </Route>
            <Route path="/about">
            <About />
            </Route>
            <Route path="/dashboard">
            <Dashboard />
            </Route>
        </Switch>
    </div>
);

export default Routes;