import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";

import logo from './logo.png';
import hamburger from './hamburger-menu.png';
import './App.css';
import Routes from './Routes';

export default function BasicExample() {
  setTimeout(() => {
    let mainNav = document.getElementById('js-menu');
    let navBarToggle = document.getElementById('js-navbar-toggle');

    navBarToggle.addEventListener('click', function () {
      mainNav.classList.toggle('active');
    });
  }, 2000);

  return (
    <Router>
      <nav className="navbar">
        <div></div>
        <span className="navbar-toggle" id="js-navbar-toggle">
            <img className="nav-toggle" src={hamburger} />
        </span>
      
        {/* <ul className="main-nav" id="js-menu">
            <li>
              <Link to="/" className="nav-links">Home</Link>
            </li>
           <li>
              <Link className="nav-links" onClick={() => {document.querySelector('.news').scrollIntoView();} }>News</Link>
            </li>
            <li>
              <Link className="nav-links" onClick={() => {document.querySelector('.about').scrollIntoView();} }>About</Link>
            </li>
        </ul>  
        <Link to="/" className="logo"><img src={logo} className="nav-logo" alt="logo" /></Link> */}
        
        <div></div>
      </nav>
      <Routes />
    </Router>
  );
}